.list{
    .item__vendor_code{
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      opacity: 0.8;
    }
    .item__price {
      display: none;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }
    .item__name{
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      padding-bottom: 8px;
      a {
        color: #32588D;
      }
    }
    .item__field{
      padding: 2px 0;
      .title{
        color:#999999;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
      }
      .description{
        color: #4E4E4E;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
  //.btn_favorites{
  //  display: flex;
  //  cursor: pointer;
  //  .title{
  //    color: #32588D;
  //    font-weight: 500;
  //    font-size: 12px;
  //    line-height: 16px;
  //    padding-left: 9px;
  //  }
  //}
  //.price__container{
  //  .price{
  //    font-weight: 600;
  //    font-size: 16px;
  //    line-height: 24px;
  //  }
  //}
  //.remainder{
  //  font-weight: 600;
  //  font-size: 16px;
  //  line-height: 24px;
  //  color:rgba($color: #32588D, $alpha: 0.8) ;
  //}
  //.box{
  //  text-align: end;
  //  padding-top: 8px;
  //  display: flex;
  //  justify-content: flex-end;
  //}
