@import "src/App/style/colors";
@import 'src/App/style/media';

.filters {
    .fields_container{
        gap: 34px;
        display: flex;
    }
    .filters-title{
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: $defautlBlue;
        margin: 32px 0 10px;
    }
    .filter-title{
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: $defautlBlue;
        padding-bottom: 8px;
        white-space: nowrap;
    }
    .dropdowns {
        display: flex;
        gap: 36px;
        padding: 20px 0;
    }
    &.mobile {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: fixed;
        top: 64px;
        left: 0;
        height: calc(100% - 64px);
        width: 100%;
        padding: 32px;
        opacity: 0;
        transform: translateY(20px);
        transition: all 0.3s ease;
        background: #fff;
        z-index: -1;
        overflow: auto;
        &.active {
            transform: translateY(0);
            opacity: 1;
            z-index: 1;
        }
        &.property {
            .close_icon {
                transform: rotate(-90deg);
            }
        }
        .filters-mobile-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .title {
                font-weight: 600;
                font-size: 28px;
                line-height: 40px;
            }
        }
        .close_icon {
            display: flex;
            width: 32px;
            height: 32px;
            align-items: center;
            justify-content: center;
            margin-right: 4px;
            transition: all 0.3s ease;
            user-select: none;
            cursor: pointer;
        }
        .fields_container {
            display: flex;
            flex-direction: column;
            gap: 12px;
        }
        & > .button {
            width: 100%;
            min-height: 48px;
        }
        .dropdowns {
            flex-grow: 1;
            flex-direction: column;
            padding-top: 18px;
            .dropdown {
                transform: none; // for fixed menu to go to the top
                .menu {
                    position: fixed;
                    top: 130px; // header - 40px, subtitle - 16px ,paddings - 74px
                    left: 0;
                    width: 100%;
                    height: calc(100% - 210px) !important; // header - 40px, button - 48px, subtitle - 16px, paddings - 106px
                    max-height: unset;
                    padding: 32px;
                    padding-top: 0 !important;
                    box-shadow: none !important;
                    transform: translateX(-20px) !important;
                    opacity: 0;
                    transition: all 0.3s ease;
                    &.visible {
                        transform: translateX(0) !important;
                        opacity: 1;
                    }
                }
            }
            .custom_arrow {
                transform: rotate(-90deg);
                margin-top: -18px;
                margin-right: -2px;
            }
        }
    }
}

@media(max-width: $media-lg) {
    .filters {
        .dropdowns {
            gap: 12px;
        }
    }
}

@media(max-width: $media-md) {
    .filters {
        &:not(&.mobile) {
            display: none;
        }
    }
}

@media(max-width: $media-sm) {
    .filters {
        &.mobile {
            padding: 24px;
            .filters-mobile-title {
                .title {
                    font-size: 20px;
                }
            }
            .dropdowns {
                .dropdown {
                    .menu {
                        top: 122px; // header - 40px, subtitle - 16px ,paddings - 66px
                        height: calc(100% - 194px) !important; // header - 40px, subtitle - 16px, button - 48px, paddings - 90px
                        padding: 24px;
                    }
                }
            }
        }
    }
}