@import 'src/App/style/media';

.side-bar{
    height: 100%;
    position: absolute;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    //transition: all 0.3s ease;
    &.active{
        opacity: 1;
        visibility: visible;
        .overlay{
            transition: all 0.3s ease;
            opacity: 1;
            visibility: visible;
        }
        .content{
            display: flex;
            flex-direction: column;
            opacity: 1;
            visibility: visible;
            transition: all 0.3s ease;
            transform: translateX(0px);
        }
    }
    .overlay{
        position: absolute;
        z-index: 20;
        background-color: rgba($color: #000000, $alpha: 0.6);
        width: 100%;
        height: 100%;
        transition: all 0.3s ease;
        opacity: 0;
        visibility: hidden;
    }
    .content{
        width: 390px;
        background-color: #fff;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 21;
        transition: all 0.3s ease;
        opacity: 0;
        visibility: hidden;
        transform: translateX(-20px);
        padding: 32px;
        box-sizing: border-box;
        .title_container{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .title{
                font-weight: 600;
                font-size: 24px;
                line-height: 32px;
                color: #222222;
            }
            .close_icon{
                // width: 16px;
                // height: 16px;
                cursor: pointer;
            }
        }
        button {
            width: 100%;
        }
    }
}

@media(max-width: $media-sm) {
    .side-bar {
        margin-top: 64px;
        height: calc(100svh - 64px);
        .content {
            width: 100%;
            padding: 24px;
        }
    }
}
