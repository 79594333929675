@import "src/App/style/colors";
@import 'src/App/style/media';

.devider{
  padding: 20px 0;
}
.characteristics{
  grid-column: 1;
  &-content{
    flex-direction: column;
    background-color:#32588D;
    border-radius: 30px;
    padding: 32px;
    box-sizing: border-box;
  }
  &-list{

    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-gap: 40px;
    &__characteristic{
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      .title{
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #fff;
        padding-bottom: 4px;
        white-space: nowrap;
      }
      .description{
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #E6E6E6;
      }
    }
  }
}

@media(max-width: $media-md) {
  .characteristics{
    &-list{
      grid-template-columns: repeat(2,1fr);
      grid-gap: 32px;
    }
  }
}

@media(max-width: $media-xs) {
  .characteristics{
    &-content {
      padding: 16px;
      border-radius: 20px;
      grid-gap: 16px;
    }
  }
}