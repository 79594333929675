@import 'src/App/style/media';

.forgot_password_form{
    padding-top: 40px;
  height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    .react-code-input {
      display: flex !important;
      justify-content: space-between;

      input {
        width: 68px;
        height: 68px;
        text-align: center;
      }
    }
    .field_container{
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding-bottom: 16px;
      .label_container{ 
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      label{
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #999999;
      }
    }
    .additional_btn{
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #32588D;
        cursor: pointer;
    }
    #btn_2{ 
        text-align: center;
        padding-top: 24px;
    }
    .button {
      height: 56px;
    }
  }

@media(max-width: $media-sm) {
  .forgot_password_form {
    padding-top: 32px;
    .react-code-input {
      justify-content: center;
      gap: 14px;
    }
  }
}

@media(max-width: $media-xs) {
  .forgot_password_form {
    .react-code-input {
      gap: 8px;

      input {
        width: 64px;
        height: 64px;
      }
    }
  }
}