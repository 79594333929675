@import 'src/App/style/media';

.auth_form{
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 40px;
  .error-text {
    margin-top: -2px;
    color: #FF5D1A;
    font-size: 12px;
    line-height: 16px;
  }
  .field_container{
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-bottom: 16px;
    label{
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #999999;
    }
  }
  .buttons_container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 8px;
    .button {
      height: 56px;
    }
    .link_container{
      display: flex;
      flex-direction: column;
      text-align: center;
      gap: 16px;
      padding-top: 16px;
      .link{
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        cursor: pointer;
      }
    }
  }
}

@media(max-width: $media-sm) {
  .auth_form{
    padding-top: 32px;
    .buttons_container {
      flex-direction: column-reverse;
      justify-content: space-between;
      gap: 0;
    }
  }
}
