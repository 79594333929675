@import '../../../App/style/colors';
@import 'src/App/style/media';

.company-page {
    .video {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100svh;
        z-index: -1;
        object-fit: cover;
        object-position: center;
    }
    .safari_container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100svh;
        z-index: -1;

        .item {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
    .about {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        gap: 32px;
        align-items: flex-start;
        justify-content: space-between;
        padding: 32px 60px;
        background-color: rgba(50, 88, 141, 0.5);
        .column {
            width: 358px;
            &:nth-of-type(3) {
                width: 565px;
            }
            .title {
                margin-bottom: 12px;
                font-size: 20px;
                line-height: 24px;
                font-weight: bold;
                color: #fff;
            }
            .text, a {
                display: flex;
                flex-direction: column;
                gap: 4px;
                font-size: 14px;
                line-height: 20px;
                color: #fff;
            }
        }
    }
}

@media(max-width: $media-md) {
    .company-page {
        .about {
            flex-direction: column;
            padding: 32px;
            gap: 8px;
            .column {
                width: unset !important;
                .title {
                    font-size: 12px;
                    line-height: 18px;
                    margin-bottom: 4px;
                }
                .text, a {
                    font-size: 10px;
                    line-height: 12px;
                }
            }
        }
    }
}

@media(max-width: $media-sm) {
    .company-page {
        .about {
            padding: 24px;
        }
    }
}