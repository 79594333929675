@import 'src/App/style/media';

.contact-modal {
  .modal-content {
    .title {
      font-weight: 600;
      font-size: 40px;
      line-height: 48px;
    }

    .description {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      padding: 16px 0 24px 0;
    }

    .special-label {
      display: none;
    }

    .form-control {
      margin-bottom: 24px;
    }

    .back {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #32588D;
      cursor: pointer;
      padding-top: 32px;
    }

    .button {
      height: 48px;
    }

    p {
      flex-wrap: wrap;
      a {
        margin-left: 4px;
      }
    }
  }
}

@media(max-width: $media-lg) {
  .contact-modal {
    .modal-content {
      .title {
        font-size: 32px;
      }
    }
  }
}

@media(max-width: $media-sm) {
  .contact-modal {
    .modal-content {
      .title {
        font-size: 24px;
        line-height: 32px;
      }

      .description {
        padding-bottom: 16px;
      }

      .form-control {
        margin-bottom: 16px;
      }

      .back {
        padding-top: 8px;
      }
    }
  }
}
