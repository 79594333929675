@import 'src/App/style/media';

.card{
  display: flex;
  border-bottom: 1px solid #E6E6E6;
  padding: 20px 0;
  gap: 30px;
  .details {
    position: relative;
    display: flex;
    width: 100%;
    gap: 30px;
    .img_container{
      //margin-right: 30px;
      border: 1px solid #999999;
      border-radius: 12px;
      overflow: hidden;
      height: 185px;
      min-width: 185px;
      max-width: 185px;
      overflow: hidden;
      img{
        height: 100%;
        width: 100%;
      }
    }
    .options{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      flex-grow: 1;
    }
  }
  .feature__container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    padding-right: 22px;
  }
}

@media(max-width: $media-md) {
  .card{
    flex-direction: column;
    gap: 16px;
    .details {
      flex-direction: column;
      gap: 16px;
      .options {
        .list:first-of-type {
          position: absolute;
          top: 0;
          left: 215px; // gap - 30px, 185px - image width
          display: flex;
          flex-direction: column;
          height: 185px; // image height
          width: calc(100% - 217px); // image height - 185px, gap - 32px

          .item__price {
            display: block;
            align-self: flex-end;
            justify-self: flex-end;
            height: 100%;
            display: flex;
            align-items: flex-end;
          }
        }
      }
    }
    .feature__container {
      padding-right: 0;
      .price__container {
        display: none;
      }
      .box {
        align-items: flex-end;
        width: 100%;
        min-height: 40px;
        .button:not(.onlyIcon) {
          width: 120px;
          height: 40px;
        }
      }
    }
  }
}

@media(max-width: $media-sm) {
  .card{
    .details {
      .img_container{
        min-width: 120px;
        max-width: 120px;
        height: 120px;
      }
      .options {
        .list:first-of-type {
          left: 150px; // gap - 30px, 120px - image width
          height: 120px; // image height
          width: calc(100% - 152px); // image height - 185px, gap - 32px
        }
      }
    }
  }
}
