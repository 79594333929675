.basket{
 .title{
   color: rgba($color: #fff, $alpha: 0.8);
   font-weight: 600;
   font-size: 14px;
   line-height: 24px;
   cursor: pointer;
   padding-top: 3px;
 }
}
