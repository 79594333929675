@import 'src/App/style/media';

.contact-page{
    padding: 32px 0 64px;
    .wrapper {
        padding: 0 40px 0 64px;
        .contact-page__title{
            font-weight: 600;
            font-size: 32px;
            line-height: 40px;
            br {
                display: none;
            }
        }
        .page_container{
            display: flex;
            justify-content: space-between;
            gap: 126px;
        }
        .contact-page__information{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: inherit;
            margin-top: 54px;
            width: 60%;
            .group {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                &.mobile {
                    display: none;
                }
            }
            .contact{
                display: flex;
                .icon-container{
                    padding-right: 16px;
                }
                .contact_container{
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    .title{
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 24px;
                    }
                    .contact_container__content{
                        .title{
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 20px;
                            color: #999999;
                            margin-bottom: 4px;
                        }
                        .description{
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 20px;
                        }
                    }
                }
            }
        }
        .map {
            width: 40%;
            max-width: 400px;
            max-height: 600px;
            height: auto;
            margin: auto;
        }
    }
}

@media(max-width: $media-lg) {
    .contact-page {
        .wrapper {
            padding: 0 40px;
            .page_container{
                gap: 64px;
            }
            .contact-page__information {
                gap: 32px;
                margin-top: 32px;
                width: 50%;
                .group {
                    flex-direction: column;
                    gap: 24px;
                }
            }
        }
        .map {
            width: unset;
        }
    }
}

@media(max-width: 1080px) {
    .contact-page {
        padding-bottom: 32px;
        .wrapper {
            padding: 0 40px;
            .contact-page__title {
                font-size: 28px;
            }
            .page_container {
                gap: 32px;
                flex-direction: column;
            }
            .contact-page__information {
                width: 100%;
                gap: 32px;
                margin-top: 32px;
                .group {
                    display: none;
                    &.mobile {
                        display: flex;
                    }
                }
                .contact {
                    .contact_container {
                        .title {
                            font-size: 16px;
                        }
                    }
                }
            }
            .group {
                flex-direction: column;
                gap: 24px;
            }
            .map{
                width: 100%;
                max-width: unset;
                min-height: unset;
                height: 410px;
            }
        }
    }
}

@media(max-width: $media-sm) {
    .contact-page {
        padding: 24px 0;
        .wrapper {
            padding: 0 24px;
            .contact-page__title {
                font-size: 20px;
                br {
                    display: block;
                }
            }
        }
    }
}
