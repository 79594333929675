@import 'src/App/style/colors';

.navigation_menu{
    padding-top: 40px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    .nivagation_list{ 
        display: flex;
        flex-direction: column;
        gap: 40px;
        .navigation{
            display: flex;
            align-items: center;
            position: relative;
            width: fit-content;
            color: $defautlBlue;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            cursor: pointer;
            &:before {
                content: '';
                position: absolute;
                bottom: -8px;
                left: 0;
                width: 100%;
                height: 1px;
                background: $defautlBlue;
            }
            img {
                margin: 0 12px 0 2px;
            }
        }
    }
    .button {
        height: 56px;
    }
}