@import 'src/App/style/colors';
@import 'src/App/style/media';

.header{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $secondaryTheme;
    height: 88px;
    position: relative;
    .wrapper {
        padding: 0 40px;
    }
    &-content{
        h1{
            padding-left: 24px;
        }
    }
    &.home {
        background-color: transparent;
    }
    &.company {
        background-color: rgba(50, 88, 141, 0.5);
    }
    .wrapper {
        width: 100%;
    }
    &-content{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .click-ignore {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
        .logo{
            flex-grow: 0.2;
            z-index: 3;
            img {
                margin-top: 2px; //visual align
            }
        }
        .sub-logo{
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            display: none;
        }
    }
    .list-pages{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 2px;
        margin-right: 16px;

        &__page{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 56px;
            border: 1.5px solid transparent;
            border-radius: 12px;
            padding: 0 16px;
            &.active {
                border-color: #fff ;
            }
            a{
                color: rgba($color: #fff, $alpha: 0.8);
                font-weight: 600;
                font-size: 14px;
                line-height: 24px;
                cursor: pointer;
            }
        }
    }
    nav{
        position: relative;
        z-index: 3;
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 40px;
    }
    .mobile-menu{
        display: none;
        z-index: 3;
    }
}

@media(max-width: 1350px) {
    .header {
        .list-pages {
            margin-left: 0;
        }
        nav{
            gap: 32px;
        }
    }
}

@media(max-width: $media-lg) {
    .header{
        .list-pages {
            margin-right: 0;
        }
        nav{
            gap: 24px;
        }
    }
}

@media(max-width: 1080px) {
    .header{
        height: 64px;
        padding: 16px 0;
        &-content{
            h1{
                padding-left: 0;
            }
        }
        nav{
            .list-pages__page,
            .search-container {
                display: none;
            }
            .basket {
                .icon {
                    width: 28px;
                    height: 28px;
                }
                .title {
                    display: none;
                }

                &_counter {
                    top: -5px;
                    right: -13px;
                }
            }
        }
        .mobile-menu{
            display: flex;
            align-items: center;
            margin-left: 24px;
            cursor: pointer;

            img {
                width: 32px;
                height: 32px;
            }
        }
    }
}

@media(max-width: $media-md) {
    .header {
        .wrapper {
            padding: 0 32px;
        }
    }
}

@media(max-width: $media-sm) {
    .header {
        .basket {
            display: none;
        }
        &.with-aside{
            background-color: $secondaryTheme;
        }
        .wrapper {
            padding: 0 24px;
        }
        &-content{
            .click-ignore {
                display: block;
            }
            nav {
                pointer-events: none;
                .basket {
                    pointer-events: all;
                }
            }
        }
    }
}

@media(max-width: $media-xs) {
    .header {
        .mobile-menu {
            margin-left: 16px;
        }
        nav {
            gap: 16px;
        }
    }
}
