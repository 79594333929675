@import 'src/App/style/colors';

.ui.input {
  height: 48px;
  input {
    border: 2px solid $lightGray;
    border-radius: 8px;
  }
  &.error {
    input {
      border-color: #FF5D1A;
      background: none;
    }
  }
  &.disabled {
    input {
      border-color: transparent;
      background: $lightGray;
    }
  }
}