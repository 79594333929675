@import 'src/App/style/colors';

.client_data_form{
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    height: 100%;
    .fields{
        .title{ 
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: #4E4E4E;
            padding-bottom: 32px;
        }
        .field_container{ 
            display: flex;
            flex-direction: column;
            gap: 8px;
            padding-bottom: 16px;
            label{
              font-weight: 500;
              font-size: 12px;
              line-height: 16px;
              color: #999999;
            }
            .error-text {
                margin-top: -2px;
                color: #FF5D1A;
                font-size: 12px;
                line-height: 16px;
            }
        }
    }
    .button {
        height: 56px;
    }
}