@import "src/App/style/colors";
@import "src/App/style/media";

.product-headline {
  .product_image {
    display: none;
  }
  .product-title {
    .vendor-code{
      font-weight: 600;
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 4px;
      color: $defaultTextColor;
    }
    .name{
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      text-transform: capitalize;
    }
  }
  & > .product-title {
    display: none;
    padding: 24px;
    border-radius: 30px;
    background: $tile;
  }
  .product-container{
    background: $tile;
    border-radius: 32px;
    padding: 24px;
    box-sizing: border-box;
    .product-title{
      margin-bottom: 28px;
    }
    .mobile_image{
      display: none;
    }
    .list{
      display: flex;
      flex-direction: column;
      gap: 4px;
      &__item{
        display: flex;
        justify-content: space-between;
        .title{
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: $gray;
        }
        .description{
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }

    .price-container{
      margin-bottom: 32px;
      .amount{
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
        .title{
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: $gray;
        }
        .description{
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
        }
      }
      .price{
        font-weight: 600;
        font-size: 32px;
        line-height: 40px;
      }

      .ndc{
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $gray;

      }
    }
    .btn-container{
      display: flex;
      justify-content: space-between;
      margin: 32px 0;
      .button {
        height: 32px;
        padding: 0 8px;
      }
    }
    .basket {
      display: flex;
      justify-content: center;
      min-height: 48px;
      .button:not(.onlyIcon) {
        width: 100%;
        min-height: 48px;
      }
      .counter {
        width: 100%;
        gap: 0;
        .btn_delete {
          width: 50%;
        }
        .right {
          justify-content: space-between;
          width: 50%;
          .counter__content {
            transform: translateX(-28px); // basket button
          }
        }
      }
    }
  }
}

@media(max-width: $media-lg) {
  .product-headline {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    & > .product-title {
      display: block;
    }
    .product_image {
      display: block;
    }
    .product-container {
      & > .product-title {
        display: none;
      }
    }
  }
}

@media(max-width: $media-md) {
  .product-headline {
    .product-title {
      .vendor-code {
        font-size: 28px;
        line-height: 32px;
      }
    }
    .product-container {
      .price-container{
        margin-bottom: 16px;
        .amount{
          display: flex;
          justify-content: space-between;
          margin-bottom: 8px;
          .title{
            font-size: 12px;
            line-height: 16px;
          }
          .description{
            font-size: 12px;
            line-height: 16px;
          }
        }
        .price{
          font-size: 20px;
          line-height: 24px;
        }
        .ndc{
          font-size: 12px;
          line-height: 16px;
        }
      }
      .list {
        gap: 2px;
        &__item {
          .title {
            font-size: 12px;
            line-height: 16px;
          }
          .description {
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
      .btn-container {
        margin: 16px 0;
        .button {
          font-size: 0;
          background: $lightGray;
          &:after {
            content: '2D.pdf';
            font-size: 12px;
          }
          &:last-of-type {
            &:after {
              content: '3D.stp'
            }
          }
        }
      }
    }
  }
}

@media(max-width: $media-sm) {
  .product-headline {
    .product-title {
      .vendor-code {
        font-size: 20px;
        line-height: 24px;
      }
    }
  }
}