@import "src/App/style/colors";

.counter{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  width: fit-content;
  height: 28px;
  .right {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  .btn_delete{
    display: flex;
    align-items: center;
    cursor: pointer;
    .title{
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #32588D;
      opacity: 0.9;
      padding-left: 4px;
    }
  }
  &__content{
    display: flex;
    align-items: center;
    .count{
      padding: 0 10px;
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
    }
  }
  &_btn,
  .button{
    border: 0.875px solid #E6E6E6;
    height: 28px;
    width: 28px;
    border-radius: 5.25px !important;
    cursor: pointer;
    padding: 7px;
  }
  .button {
    border: none !important;
    background: $defautlBlue !important;
  }
}
