@import 'src/App/style/colors';
@import 'src/App/style/media';

.mb-wrapper {
    .overlay {
        position: fixed;
        height: 100svh;
        width: 100vw;
        left: 0;
        top: 0;
        z-index: 10;
    }

    .mb{
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        width: 390px; // same as side-bar
        z-index: 20;
        background-color: #fff;
        box-shadow: 1px 0 16px -6px $darkGrey; // for light backgrounds
        &.menu{
            &.disable{
                transform: translateX(20px);
                transition: all 0.3s ease;
                opacity: 0;
                visibility: hidden;
            }
            &.enable{
                transform: translateX(0);
                transition: all 0.3s ease;
                opacity: 1;
                visibility: visible;
            }
        }
        .content{
            padding: 24px;
            z-index: 20;
            display: flex;
            flex-direction: column;
            // grid-template-rows: 20px 20px 1fr 1fr ;
            // gap: 10px;
            .input_container{
                display: flex;
                align-items: center;
                margin: 45px 0;
                input{
                    border: none;
                    outline: none;
                    background-color: transparent;
                    flex-grow: 1;
                    margin-left: 8px;
                }
            }
            .list__pages{
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                gap: 40px;
                a {
                    width: fit-content;
                }
                &__page{
                    display: flex;
                    align-items: center;
                    position: relative;
                    width: fit-content;
                    color: $defautlBlue;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 24px;
                    cursor: pointer;
                    &:before {
                        content: '';
                        position: absolute;
                        bottom: -8px;
                        left: 0;
                        width: 100%;
                        height: 1px;
                        background: $defautlBlue;
                    }
                    img {
                        margin: 0 12px 0 2px;
                    }
                    a{
                        color: #fff;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
            }
            &__header {
                display: flex;
                align-self: center;
                justify-content: space-between;
                width: 100%;
                margin-bottom: 40px;
                padding-left: 8px;
                h3 {
                    display: flex;
                    align-self: center;
                    font-weight: 600;
                    font-size: 24px;
                }
                .close-icon {
                    cursor: pointer;
                }
            }
            .mobile-logo{
                align-self: center;
            }
        }
    }
}

@media(max-width: $media-sm) {
    .mb-wrapper {
        .overlay {
            display: none;
        }
        .mb {
            position: fixed;
            top: 64px;
            left: 0;
            width: unset;
        }
    }
}
