@import 'src/App/style/media';
@import 'src/App/style/colors';

.basket-page{
  padding: 32px 0;
  &.empty {
    height: calc(100svh - 88px);
    .title-container {
      .title-button {
        display: none;
      }
    }
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .title-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    justify-content: space-between;
    margin-bottom: 24px;
    .title-button {
      display: flex;
      align-items: center;
      gap: 4px;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: $defautlBlue;
      cursor: pointer;
    }
    .close-icon {
      display: none;
      width: 40px;
      height: 40px;
      cursor: pointer;
      img {
        width: 40px;
        height: auto;
      }
    }
    .title{
      font-weight: 600;
      font-size: 32px;
      line-height: 40px;
    }
  }
  .content{
    display: flex;
    gap: 24px;
  }
  .left-side{
    width: 100%;
  }
  .right-side{
    min-width: 302px;
    height: fit-content;
    transition: all 0.3s ease;
    background: #F7F7F7;
    border-radius: 30px;
    padding: 32px;
    box-sizing: border-box;
    .price-container{
      .title{
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #32588D;
      }
      .price{
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
      }
      .ndc{
        margin-top: 16px;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #999;
      }
    }
    .divider {
      margin: 16px 0 32px 0;
    }
    .btn-container{
      display: flex;
      flex-direction:column ;
      gap: 16px;
      padding-bottom: 8px;
      .button {
        height: 48px;
      }
    }
    .control-price{
      h4{
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #999999;
      }
      h5{
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #32588D;
      }
    }
  }
  .card {
    .about {
      display: none;
    }
    .box {
      .counter {
        .right {
          & > div:last-of-type {
            display: none;
          }
        }
      }
    }
  }
}

@media(max-width: $media-lg) {
  .basket-page {
    &.empty {
      height: calc(100svh - 66px);
    }
    .wrapper {
      height: 100%;
    }
    .right-side {
      min-width: unset;
    }
    .content {
      flex-direction: column;
    }
  }
}

@media(max-width: $media-md) {
  .basket-page {
    .title-container {
      align-items: center;
      flex-direction: row;
      .close-icon {
        display: block;
      }
      .title {
        font-size: 28px;
      }
    }
    .card {
      .box {
        .counter {
          display: flex;
          gap: 0;
          justify-content: space-between;
          width: 100%;
        }
      }
    }
  }
}

@media(max-width: $media-sm) {
  .basket-page {
    padding: 24px 0;
    .title-container {
      .title {
        font-size: 20px;
      }
    }
  }
}

@media(max-width: $media-xs) {
  .basket-page {
    .right-side {
      padding: 24px;
    }
  }
}
