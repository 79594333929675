@import 'src/App/style/media';
@import 'src/App/style/colors';

.complete-order {
  padding: 32px 0;
  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    .item__field.about {
      display: none;
    }
    & > .title:first-of-type {
      font-weight: 600;
      font-size: 32px;
      line-height: 40px;
    }
    .content {
      display: flex;
      gap: 24px;
      .left-side {
        width: 100%;
        .card {
          &:nth-of-type(2) {
            border-top: 1px solid $lightGray;
          }
          .feature__container {
            align-items: flex-start;
            & > .price__container {
              align-items: flex-start;
            }
            .box {
              .price__container {
                .price {
                  font-weight: 500;
                }
              }
            }
          }
        }
        .about {
          display: flex;
          align-items: center;
          height: 92px;
          .status-container {
            width: 256px;
            align-items: flex-start;
          }
          .description {
            span {
              color: $defaultTextColor;
              font-size: 12px;
              line-height: 18px;
            }
            .title {
              color: $gray;
            }
          }
        }
      }
      .right-side {
        min-width: 302px;
        height: fit-content;
        transition: all 0.3s ease;
        background: #F7F7F7;
        border-radius: 30px;
        padding: 32px;
        box-sizing: border-box;

        .price-container {
          padding-bottom: 0;
          .title {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: #32588D;
          }

          .price {
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;
          }

          .ndc {
            margin-top: 16px;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: #999;
          }
        }
        .divider {
          margin: 16px 0 32px 0;
        }
        .button {
          width: 100%;
        }
      }
    }
  }
}


@media(max-width: $media-lg) {
  .complete-order {
    .wrapper {
      height: 100%;
      .left-side {
        .card {
          .feature__container {
            .box {
              .price__container {
                display: block;
              }
              }
            }
          }
        }
      .right-side {
        min-width: unset;
      }
      .content {
        flex-direction: column;
        gap: 48px;
      }
    }
  }
}

@media(max-width: $media-md) {
  .complete-order {
    .wrapper {
      & > .title:first-of-type {
        font-size: 28px;
      }
      .content {
        .left-side {
          .about {
            flex-direction: column;
            align-items: flex-start;
            height: unset;
            margin-bottom: 8px;
            .status-container {
              flex-direction: row;
              align-items: center;
              gap: 32px;
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media(max-width: $media-sm) {
  .complete-order {
    padding: 24px 0;
    .wrapper {
      & > .title:first-of-type {
        font-size: 20px;
      }
      .content {
        .left-side {
          .about {
            .status-container {
              justify-content: space-between;
              gap: 0;
            }
          }
        }
      }
    }
  }
}

@media(max-width: $media-xs) {
  .complete-order {
    .right-side {
      padding: 24px;
    }
  }
}