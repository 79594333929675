@import 'src/App/style/media';

.optional-equipment{
  display: flex;
  flex-direction: column;
  gap: 32px;
  .name{
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
  }
}

@media(max-width: $media-md) {
  .optional-equipment{
    .name {
      font-size: 28px;
    }
    .card {
      .list {
        .item__field {
          &:last-of-type {
            display: none;
          }
        }
      }
    }
  }
}

@media(max-width: $media-sm) {
  .optional-equipment {
    .name{
      font-size: 20px;
    }
  }
}
