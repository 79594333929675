@import 'src/App/style/media';
@import 'src/App/style/colors';

.product_image{
  border: 2px solid $gray;
  border-radius: 30px;
  width: 100%;
  height: 100%;
  min-height: 320px; // for no img
  object-fit: contain;
}

.product-page{
  padding: 64px 0;
  &__content{
    display: flex;
    gap: 32px;
    .left-side {
      display: flex;
      flex-direction: column;
      gap: 32px;
      width: calc(100% - 404px);
      height: 100%;
    }
    .right-side {
      min-width: 404px;
      height: fit-content;
      transition: all 0.3s ease;
      position: sticky;
      position: -webkit-sticky;
      top: 30px;
    }
  }
}

@media(max-width:$media-lg) {
  .product-page {
    padding: 56px 0;
    &__content {
      flex-direction: column-reverse;
      .left-side {
        width: 100%;
        .product_image{
          display: none;
        }
      }
      .right-side {
        width: 100%;
        min-width: unset;
        position: unset;
      }
    }
  }
  .product_image {
    border: 1px solid $gray;
  }
}


@media(max-width:$media-md) {
  .product-page {
    padding: 32px 0;
    gap: 16px;
    .wrapper {
      padding: 0 16px;
      .left-side {
        gap: 16px;
      }
    }
  }
  .product_image{
    border-radius: 20px;
    min-height: 165px; // for no img
  }
}

@media(max-width:$media-sm) {
  .product-page {
    padding: 24px 0;
  }
}
