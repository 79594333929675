@import 'src/App/style/media';
@import 'src/App/style/colors';

.product-model__content{
  border: 2px solid $gray;
  border-radius: 30px;
  grid-column: 1;
  height: 500px;
}
#modelViewer{
  width: 100%;
  height: 100%;
}

@media(max-width: $media-lg) {
  .product-model__content {
    border: 1px solid $gray;
  }
}

@media(max-width: $media-md) {
  .product-model__content {
    border-radius: 20px;
  }
}