@import 'src/App/style/colors';

.mbs {
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 100%;
    padding-top: 40px;

    .input {
        min-height: 48px;
        width: 100%;

        input {
            padding-left: 48px;
            border-radius: 4px;
            font-size: 14px;
            color: $gray;

            &:focus {
                border-color: $lightGray;
            }
        }

        .icon {
            // overwrite semantic
            top: 50% !important;
            left: 10px !important;
            transform: translateY(-50%);
            width: 24px !important;
            height: 24px !important;
        }
    }

    .results {
        height: 100%;
        min-width: calc(100% + 32px); // for scroll to go to the right
        overflow-y: auto;
        padding-right: 32px;
        padding-bottom: 32px;

        &::-webkit-scrollbar {
            width: 2px;
            height: 74px;
            color: #999;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        .not_found {
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $gray;
            font-size: 18px;
        }
    }

    .products-list {
        display: flex;
        flex-direction: column;
        gap: 32px;
        padding-top: 32px;

        a:last-of-type {
            .search-result {
                border-bottom: none;
            }
        }

        &__product {
            padding: 16px 0;

            .product-headline {
                display: flex;
                align-items: center;

                .image_container {
                    width: 100px;

                    img {
                        width: 80px;
                        height: 100px;
                        object-fit: contain;
                    }
                }

                .container {
                    padding-left: 24px;
                    box-sizing: border-box;

                    .title {
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                    }

                    .vendor_code {
                        padding-top: 8px;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 20px;
                        color: #999999;
                    }
                }
            }
        }
    }
}