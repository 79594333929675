@import 'src/App/style/media';

.my-order-page{
  padding: 32px 0;
  &.empty {
    height: calc(100svh - 88px);
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    .close-icon {
      display: none;
      width: 40px;
      height: 40px;
      cursor: pointer;
      img {
        width: 40px;
        height: auto;
      }
    }
    .title{
      font-weight: 600;
      font-size: 32px;
      line-height: 40px;
    }
  }
}

@media(max-width: $media-lg) {
  .my-order-page {
    &.empty {
      height: calc(100svh - 66px);
    }
    .wrapper {
      height: 100%;
    }
  }
}

@media(max-width: $media-md) {
  .my-order-page {
    .title-container {
      .close-icon {
        display: block;
      }
      .title {
        font-size: 28px;
      }
    }
  }
}

@media(max-width: $media-sm) {
  .my-order-page {
    padding: 24px 0;
    .title-container {
      .title {
        font-size: 20px;
      }
    }
  }
}

