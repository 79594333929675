@import 'src/App/style/media';

.status-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 200px;

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    margin: 0 0 7px 0;
    color: #999999;
  }

  .status {
    .status-content {
      display: flex;
      align-items: center;
      margin-bottom: 12px;

      .img {
        width: 24px;
        height: 24px;
        margin-right: 10px;
        background-repeat: no-repeat;
      }

      p {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        margin: 0;
      }
    }

    &.invoice-request {
      div:first-child {
        .img {
          background-image: url("../../../assets/icons/file-blank-menu.svg");
        }

        p {
          color: #32588d;
        }
      }

      div:nth-child(2) {
        display: none;
        .img {
          background-image: url("../../../assets/icons/ruble.svg");
        }
      }

      div:last-child {
        display: none;
        .img {
          background-image: url("../../../assets/icons/delivery.svg");
        }
      }

    }

    &.payment {
      div:first-child {
        display: none;
        .img {
          background-image: url("../../../assets/icons/file-blank-menu-success.svg");
        }

        p {
          color: #76BF74;
        }
      }

      div:nth-child(2) {

        .img {
          display: block;
          background-image: url("../../../assets/icons/ruble-pending.svg");
        }

        p {
          color: #32588d;
        }
      }

      div:last-child {
        display: none;
        .img {
          background-image: url("../../../assets/icons/delivery.svg");
        }
      }

    }

    &.delivery {
      div:first-child {
        display: none;
        .img {
          background-image: url("../../../assets/icons/file-blank-menu-success.svg");
        }

        p {
          color: #76BF74;
        }
      }

      div:nth-child(2) {
        display: none;
        .img {
          background-image: url("../../../assets/icons/ruble-success.svg");
        }

        p {
          color: #76BF74;
        }
      }

      div:last-child {
        .img {
          display: block;
          background-image: url("../../../assets/icons/delivery-pending.svg");
        }

        p {
          color: #32588d;
        }
      }
    }

    &.success {
      .img {
        display: block;
        background-image: url("../../../assets/icons/done.svg");
      }

      p {
        color: #76BF74;
      }
    }

    &.canceled {
      .img {
        background-image: url("../../../assets/icons/canceled.svg");
      }

      p {
        color: #BF7474;
      }
    }
  }

}

.info {
  width: 100%;
  .order-card {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #E6E6E6;
    padding: 32px 0;
    .order-container {
      h3 {
        cursor: pointer;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #222222;
        opacity: 0.8;
        margin: 0 0 16px 0;
      }

      .about {
        p {
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          display: flex;
          align-items: center;
          color: #999999;
        }
      }

      h2 {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #222222;
        margin: 16px 0 0 0;
      }
    }

    .status-change-container {
      .btn {
        user-select: none;
        padding: 0;
        cursor: pointer;
        opacity: 0.8;
        transition: 0.3s;
        display: flex;
        align-items: center;

        span {
          color: #32588d;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          margin-left: 5px;
        }

        &:hover {
          opacity: 1;
        }
      }
    }
    .custom-delivery-image-color {

      .img {
        -webkit-mask-image: url("../../../assets/icons/file-blank-menu-success.svg");
        mask-image: url("../../../assets/icons/file-blank-menu-success.svg");
        background-color: #76BF74;
        background-image: none !important;
      }

      p {
        color: #76BF74 !important;
      }
    }

    h1 .h1-order {
      font-weight: 600;
      font-size: 32px;
      line-height: 40px;
      color: #222222;
      margin: 0;
      padding: 0 0 32px 0;
    }

    .select {
      margin-bottom: 32px;
    }

    .search {
      margin-bottom: 32px;
      user-select: none;
      max-width: 510px;
      padding: 12px 16px;
      height: 40px;
      background: #FFFFFF;
      border: 2px solid #E6E6E6;
      border-radius: 8px;
      text-align: start;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      opacity: 0.8;
      color: #32588D;

      &:focus {
        box-shadow: unset;
      }

      &:focus-visible {
        outline-color: #32588d;
      }

      &::-webkit-search-cancel-button {
        display: none;
      }

      &::-webkit-search-decoration {
        display: none;
      }

      &::-webkit-search-results-button {
        display: none;
      }

      &::-webkit-search-results-decoration {
        display: none;
      }
    }
  }
}

@media(max-width: $media-sm) {
  .info {
    .order-card {
      flex-direction: column;
      padding-bottom: 4px;
      padding-top: 20px;
      .status-container {
        width: 100%;
        margin-top: 16px;
      }
    }
  }
}

