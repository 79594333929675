@import 'src/App/style/colors';
@import 'src/App/style/media';

.delete-modal {
  .title {
    padding-right: 48px;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
  }
  .buttons {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    margin-top: 32px;

    button:last-of-type {
      position: relative;
      background: none;
      border: 2px solid $defautlBlue;
      color: $defautlBlue;

      &:hover,
      &:focus {
        background: #284671;
        border-color: #284671;
        color: #fff;
      }
    }
  }
}

@media(max-width: $media-sm) {
  .delete-modal {
    .buttons {
      margin-top: 16px;
      button:last-of-type {
        color: #fff;
        &:hover,
        &:focus {
          color: #284671;
          &:before {
            color: #fff;
          }
        }
        &:before {
          content: 'Отменить';
          position: absolute;
          top: 50%;
          left: 50%;
          translate: -50% -50%;
          color: $defautlBlue;
          z-index: 1;
        }
      }
    }
  }
}

@media(max-width: $media-xs) {
  .delete-modal {
    .title {
      font-size: 20px;
    }
  }
}