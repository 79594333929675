//@import '../../../scss/colors.scss';
@import "src/App/style/colors";

.ui.button{
  margin: 0;
  &.primary{
    background: #32588D;
    border-radius: 12px;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    transition: all 0.3s ease;
    border: 2px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    &.notActive{
      color: #999999;
      border: 2px solid #999999;
      background: #fff;
      &:hover{
        color: #999999;
        border: 2px solid #999999;
        background: #fff;
        transition: all 0.3s ease;
      }
    }
    &.onlyIcon{
      padding: 2px;
    }
    &.maxWidth{
      width: 100%;
      align-items: center;
      justify-content: center;
    }
    &.withPadding{
      padding: 22px 34px;
    }
    &:hover{
      border: 2px solid #284671;
      color: #fff;
      background: #284671;
      transition: all 0.3s ease;
    }
  }
  &.secondary{
    background: #F6F6F6;
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: $defaultTextColor;
    display: flex;
    align-items: center;
    &.maxWidth{
      width: 100%;
    }
    &.withPadding{
      padding: 21px 21px;
      border-radius: 12px;
    }
    &:hover{
      background: #E6E6E6;
      color: $defaultTextColor;
    }
     &:active{
         background: #F6F6F6;
         color: $defaultTextColor;
     }
     &:focus{
         background: #F6F6F6;
         color: $defaultTextColor;
     }
  }
  &.delete{
    background: none;
    border-radius: 12px;
    color: #32588D;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    transition: all 0.3s ease;
    border: 2px solid #32588D;
    display: flex;
    align-items: center;
    justify-content: center;
    &.notActive{
      color: #999999;
      background: #fff;
      &:hover{
        color: #999999;
        background: #fff;
        transition: all 0.3s ease;
      }
    }
    &.onlyIcon{
      padding: 2px;
    }
    &.maxWidth{
      width: 100%;
      align-items: center;
      justify-content: center;
    }
    &.withPadding{
      padding: 22px 34px;
    }
    &:hover{

      color: #fff;
      background: #284671;
      transition: all 0.3s ease;
    }
  }
}
