.ui.secondary.menu.custom_pagination{ 
   
    & .item{ 
        padding: 7px 13px;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        border-radius: 6px;

        &.active{ 
            background-color: #32588D;
            color: #ffff;    
        }
    }
}
.ui.pagination.menu .item{ 
    min-width: 0;
}