@import '../../../App/style/colors';
@import 'src/App/style/media';

.footer{
    padding: 24px 0 ;
    background-color: #f6f6f6;
    &-content{
        display: flex;
        .footer-logo {
            width: 89px;
            height: 31px;
            cursor: pointer;
        }

        .list-pages{
            display: flex;
            flex-direction: column;
            padding: 0 64px;
            margin: -8px 0;
            &__page{
                cursor: pointer;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color:$defaultTextColor ;
                padding: 8px 0;
                white-space: nowrap;
            }
        }
        .list-contacts{
            flex-grow: 1;
            .title{
                padding-bottom: 16px;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
            }
            &__contact{
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: $darkGrey;
                display: flex;
                align-items: center;
                padding: 10px 0;
                img{
                    margin-right: 10px;
                    width: 20px;
                    height: 20px;
                }
                a{
                    display: flex;
                    align-items: center;
                    color: $darkGrey;
                }
                div {
                    display: flex;
                    gap: 4px;
                    color: inherit;
                }
            }
        }
        .right {
            display: flex;
            justify-content: space-between;
            flex-grow: 1;
            .information{
                display: flex;
                flex-direction: column;
                padding-left: 32px;
                width: 50%;
                .title{
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    padding-bottom: 12px;
                }
                .contact{
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;
                    white-space: nowrap;
                    a{
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 20px;
                        color: #999999;
                        padding: 4px 0;
                        cursor: pointer;
                    }
                }
            }
            .company {
                display: flex;
                justify-content: center;
                width: 50%;
                .visioner{
                    height: 28px;
                    width: fit-content;
                    display: flex;
                    align-items: center;
                    align-self: flex-end;
                    border-radius: 6px;
                    background-color: #ffff;
                    padding: 0 12px 0px 8px;
                    h3{
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 100%;
                        white-space: nowrap;
                        color: #040D36;
                    }
                    img:last-of-type {
                        scale: 1.15;
                        margin-top: -1px;
                        margin-left: 5px;
                    }
                }
            }
        }
    }
}

@media(max-width: $media-lg) {
    .footer {
        .wrapper {
            padding: 0 32px;
        }
        .list-contacts {
            flex-grow: unset;
        }
        .right {
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-end;
            .information, .company {
                width: unset;
            }
        }
    }
}

@media(max-width: $media-md) {
    .footer {
        &-content {
            flex-direction: column;
            gap: 32px;
            .list-pages {
                padding: 0;
                margin-top: 0;
            }
            .right {
                align-items: flex-start;
                gap: 32px;
                .company {
                    align-self: center;
                    .visioner {
                        width: fit-content;
                    }
                }
                .information {
                    padding-left: 0;
                }
            }
        }
    }
}
