@import "src/App/style/colors";
@import 'src/App/style/media';

.empty{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100svh - 216px); // 88px - header, 64 - paddings, 64px - title
  justify-content: center;
  .title_container{
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title{
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      padding-bottom: 24px;
    }
    .button {
      width: 200px;
      min-height: 64px;
      height: 64px;
    }
  }
  .image_container {
    display: flex;
    height: calc(100% - 144px);

    .image {
      display: block;
      width: 100%;
      max-width: 1280px;
      height: auto;

      &.mobile {
        display: none;
        max-width: 312px;
      }
    }
  }
}

@media(max-width: $media-md) {
  .empty{
    height: 100%;
    .title_container{
      .title {
        font-size: 16px;
      }
      .button {
        min-height: 40px;
        height: 40px;
        font-size: 12px;
      }
    }
    .image_container {
      .image {
        &.mobile {
          display: block;
        }

        &:not(&.mobile) {
          display: none;
        }
      }
    }
  }
}

@media(max-width: $media-sm) {
  .empty{
    justify-content: space-between;
    .title_container{
      width: 312px; // image width
      padding-top: 0;
      height: 100%;
      .title{
        display: flex;
        align-items: center;
        height: 100%;
        padding-bottom: 0;
        text-align: center;
        font-size: 12px;
        font-weight: 500;
        color: $darkGrey;
      }
      .button {
        width: 100%;
      }
    }
  }
}

@media(max-width: $media-xs) {
  .empty{
    .title_container{
      width: 100%;
      max-height: 360px;
    }
  }
}
