@import 'src/App/style/colors';
@import 'src/App/style/media';

.compatible-models{
  display: flex;
  flex-direction: column;
  gap: 32px;
  .name{
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
  }
  .models {
    display: flex;
    flex-direction: column;
    width: 100%;
    .model {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding: 16px;
      border-radius: 12px;
      background: $tile;
      &:nth-child(even) {
        background: none;
      }
      .title,
      .brands {
        text-align: right;
        font-size: 12px;
        line-height: 20px;
      }
    }
  }
}

@media(max-width: $media-md) {
  .compatible-models {
    .name{
      font-size: 28px;
    }
  }
}

@media(max-width: $media-sm) {
  .compatible-models {
    .name{
      font-size: 20px;
      line-height: 28px;
    }
  }
}
