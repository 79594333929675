@import 'src/App/style/media';
@import 'src/App/style/colors';

.order-success-modal {
  .modal-content {
    .title {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 24px;
    }
    .description {
      font-size: 16px;
      line-height: 24px;
      color: $defaultTextColor;
    }
  }
}

@media(max-width: $media-sm) {
  .order-success-modal {
    .modal-content {
      .title {
        margin-bottom: 16px;
      }
    }
  }
}
