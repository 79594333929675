@import 'src/App/style/media';

.ui.modal {
  width: 590px !important;
  border-radius: 8px !important;
  padding: 16px !important;
  height: fit-content;
  & >.content {
    background: none;
  }
  &.modal-border-radius {
    border-radius: 16px !important;
  }
  .btn-close {
    position: absolute;
    right: 44px;
    top: 44px;
    width: 16px;
    height: 16px;
    cursor: pointer;
    opacity: 0.8;

    &:hover,
    &:focus {
      opacity: 1;
    }
  }
}

@media(max-width: $media-lg) {
  .ui.modal {
    width: 540px !important;
  }
}

@media(max-width: $media-md) {
  .ui.modal {
    padding: 24px !important;
  }
}

@media(max-width: $media-sm) {
  .ui.modal {
    bottom: 0;
    width: 100% !important;
    border-radius: 0 !important;
    &.modal-border-radius {
      border-radius: 0 !important;
    }
    .btn-close {
      right: 25px;
      top: 20px;
    }
  }
}

@media(max-width: $media-xs) {
  .modal {
    padding: 16px !important;
  }
}
