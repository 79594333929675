.search-container {
  position: relative;
  width: 360px;
  height: 100%;

  .search_input {
    position: relative;
    width: 100%;

    input {
      border: none;
      border-radius: 4px;
    }

    .icon {
      // overwrite semantic
      top: 50% !important;
      left: 14px !important;
      transform: translateY(-50%);
      width: 24px !important;
      height: 24px !important;
    }
  }

  .my__drop-down {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    width: 100%;
    padding: 16px;
    border-radius: 16px;
    margin-top: 20px;
    box-sizing: border-box;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);

    &__list {
      display: flex;
      flex-direction: column;
      gap: 32px;
      overflow-y: auto;
      max-height: 496px;
      padding-right: 16px;

      a:last-of-type {
        .search-result {
          border-bottom: none;
        }
      }

      &::-webkit-scrollbar {
        width: 2px;
        height: 74px;
        color: #999;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }
    }

    &.active {
      transition: all 0.3s ease;
      opacity: 1;
      visibility: visible;
    }
  }

  .overflow-hidden {
    overflow: hidden;
  }
}