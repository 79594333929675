@import './colors.scss';
@import 'src/App/style/media';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap');

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video,input,button {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
    color: $defaultTextColor;
	font-family: 'Montserrat', sans-serif !important;
	text-decoration: none;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

.wrapper{ 
	max-width: 1440px;
	margin:  0 auto;
	padding: 0 20px;
}

.App{
	min-height: 100svh;
	display: flex;
	flex-direction: column;
}
.main{
	flex-grow: 1;
}
.ui.grid{ 
	margin-top: 0;
    margin-bottom:0;
    margin-left: 0;
    margin-right: 0;
}
.ui.grid>.column:not(.row){ 
	padding-top: 0; 
    padding-bottom: 0;
}
.ui.grid>.column:not(.row), .ui.grid>.row>.column{
    padding-left:0;
    padding-right: 0;
    vertical-align:auto;
}
.ui.grid>*{ 
	padding-left:0;
    padding-right:0;
}
.ui.segment{ 
	margin:0;
    padding: 0;
    border-radius: 0; 
    border: none;
}

.pushable:not(body){ 
	transform: none;
}
.wrapper {
	padding: 0 80px;
}
@media(max-width:$media-lg) {
.wrapper { 
	padding: 0 40px;
}	
		   
}

@media(max-width:$media-md) {
	.wrapper { 
		padding: 0 32px;
	}	
			   
}
@media(max-width:$media-sm) {
	.wrapper { 
		padding: 0 24px;
	}	
			   
	}