@import 'src/App/style/media';

.catalog-page{
    padding: 32px 0;
    .title-container{ 
        // padding-top: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .title{ 
            font-weight: 600;
            font-size: 32px;
            line-height: 40px;
            &.mobile {
                display: none;
            }
        }
        .button {
            display: none;
            height: 40px;
            margin-right: 0;
            padding: 0 12px;
            border-radius: 8px;
            img {
                margin-left: 4px;
            }
        }
    }
    .pagination_container{
        display: flex;
        justify-content: center;
        padding-top: 20px ;
    }
}

@media(max-width: $media-md) {
    .catalog-page {
        .title-container{
            margin-bottom: 32px;
            .title {
                font-size: 28px;
                display: none;
                &.mobile {
                    display: block;
                }
            }
            .button {
                display: flex;
            }
        }
        .card {
            .list {
                .item__field {
                    &:last-of-type {
                        display: none;
                    }
                }
            }
            .box {
                flex-direction: row;
                justify-content:flex-end;
                align-items: center;
                &.with-delivery {
                    justify-content: space-between;
                }
                .delivery {
                    display: block;
                }
                .counter {
                    .btn_delete {
                        display: none;
                    }
                }
            }
        }
    }
}

@media(max-width: $media-sm) {
    .catalog-page {
        .title-container{
            .title {
                font-size: 20px;
            }
        }
    }
}