@import 'src/App/style/colors';

.ui.dropdown>.text{
  display: block;
  padding: 4px 8px;
  height: 100%;
  width: calc(100% - 32px);
  border-radius: 4px;
  background: #F0F3F8;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
// .ui.dropdown>.text::before{ 
//   width: 100%;
//   height: 20px;
//   position: absolute;
//   z-index: -1;
//   background: #F0F3F8;
//   border-radius: 4px;

//   content: "";
// }
.ui.fluid.dropdown.custom__drop{
  border: 1px solid #D8DFEB;
  border-radius: 4px;
}
.ui.selection.active.dropdown:hover{
  border: 1px solid #D8DFEB;
  border-radius: 4px;
}
.ui.selection.dropdown.custom__drop{
  padding: 8px;
  border: 1px solid #D8DFEB;
  border-radius: 4px;
  height: 40px;
}
.ui.selection.active.dropdown:hover{
  border-color:#D8DFEB ;
  box-shadow: none;
}
.ui.selection.active.dropdown:hover .menu{
  border-color: transparent;
}
.ui.active.selection.dropdown{
  border-radius: 4px !important;
}
.ui.selection.active.dropdown .menu{
  top: 120%;
  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  border-color: transparent;
  padding: 16px;
}
.ui.selection.dropdown .menu>.item{
  display: flex;
  align-items: center;
  padding: 0 16px !important;
  width: 100%;
  height: 40px;
  border-bottom: 1px solid #F0F3F8;
  border-top:none;
  &:hover,
  &.active {
    background: #F0F3F8;
  }
}
.custom_arrow{
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 16px;

  &.disabled {
    display: none;
  }
}

.ui.dropdown.custom__drop.disabled {
  .custom_arrow {
    display: none;

    &.disabled {
      display: block;
    }
  }
}