.ui.checkbox input:checked~.box:before, .ui.checkbox input:checked~label:before{
  border: 2px solid #E0E3EB !important;
  width: 20px;
  height: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.ui.checkbox input.hidden{ 
  visibility: hidden;
  opacity: 0;
}
.ui.checkbox .box:before, .ui.checkbox label:before{
  border: 2px solid #E0E3EB ;
}
.ui.radio.checkbox .box:before, .ui.radio.checkbox label:before{
  width: 20px;
  height: 20px;
  top: 50%;
  transform: translateY(-50%);
}
ui.radio.checkbox input:focus:checked~label:after,ui.radio.checkbox input:focus:checked~label:before{
  background-color: #32588D;
}
.ui.radio.checkbox input~.box:after, .ui.radio.checkbox input~label:after{
  background-color: #32588D !important;
  width: 12px;
  height: 12px;
  top: 50%;
  left: 10px;
  transform: scale(1) translate(-50%, -50%);
}
.ui.checkbox input.hidden+label{
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}
