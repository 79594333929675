.contact-form-page{ 
    .title{ 
        font-weight: 600;
        font-size: 32px;
        line-height: 40px;
        padding: 32px 0;
    }
    .container{ 
        display: grid;
        grid-template-columns: repeat(2,1fr);
        gap: 128px;
        padding-bottom: 36px;
        &.btn{ 
            // grid-template-columns:1fr;
            display: flex;
            justify-content: center;
        }   
        .description{ 
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: #999999;
            padding-top: 16px;
        }
    }
    
    .ui.form textarea{ 
        resize: none;
        height: 200px;
    }
    .dropzone-div {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;
        border: 1px dashed #E6E6E6;
        border-radius: 8px;
        height: 200px;
        .title{ 
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            padding: 0 0 4px 0;
        }
        .description{ 
            padding: 0;
        }
    }
    .requirement__container{ 
        align-self: center;
        .title{ 
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            padding: 0 0 8px 0 ;
        }
        .list_requirements{ 
            &__requirement{ 
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                color: #999999;
                padding: 2px 0;
            }
        }
    }
}