@import 'src/App/style/media';

.list{
  .item__vendor_code{
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

  }
  .item__name{
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #32588D;
    padding-bottom: 8px;
  }
  .item__field{
    .title{
      color:#999999;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
    }
    .description{
      color: #4E4E4E;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
    }
  }
}
.btn_favorites{
  display: flex;
  cursor: pointer;
  .title{
    color: #32588D;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    padding-left: 9px;
  }
}
.price__container{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .price{
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    white-space: nowrap;
  }
}
.remainder{
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color:rgba($color: #32588D, $alpha: 0.8) ;
  padding-bottom: 8px;
  opacity: 0.8;
}
.box{
  text-align: end;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 48px;
  .button:not(.onlyIcon) {
    width: 100%;
    height: 48px;
    border-radius: 8px !important; // override .ui.button
    white-space: nowrap;
  }
  .delivery, .about {
    display: none;
    padding-right: 8px;
    text-align: start;
    .title{
      color:#999999;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
    }
    .description{
      color: #4E4E4E;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      word-break: break-word;
    }
  }
  .about {
    flex-direction: column;
    gap: 4px;
    width: 100%;
    margin-bottom: 16px;
  }
}

@media(max-width: $media-md) {
  .box {
    &.with-about {
      flex-direction: column;
      .button:not(.onlyIcon),
      .counter {
        align-self: flex-end;
      }
    }
    .about {
      display: flex;
    }
  }
}
