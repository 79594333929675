@import "src/App/style/colors";
@import 'src/App/style/media';

.headline{
   position: relative;
   height: calc(100svh - 110px);
    &-content{
        max-width: 1440px;
        width: 100%;
        margin: 0 auto;
        grid-column: 1;
        grid-row: 1;
        height: calc(100svh - 100px);
        position: relative;
        z-index: 2;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        .title-container{
            justify-self: start;
            align-self: center;
            padding: 30px 30px 30px 64px;
            box-sizing: border-box;
            .title{
                font-weight: 600;
                font-size: 56px;
                line-height: 64px;
                color: #fff;
                padding-bottom: 24px;
            }
            .description{
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
                color: rgba($color: #fff, $alpha: 0.5);
            }
        }
        .btns-container{ 
            display: flex;
            align-items: center;
            gap: 16px;
            grid-column: 1;
            grid-row: 3;
            justify-self: start;
            align-self: center;
            padding: 32px 64px;
            .container{ 
                &.first{ 
                    width: 200px;
                    .button {
                        height: 64px;
                        width: 100%;
                        padding: 22px 24px;
                        white-space: nowrap;
                    }
                }
                &.second{ 
                    width: 64px;

                    .button {
                        width: 100%;
                        height: 64px;
                    }
                }
            }
        }
        .visioner_logo{ 
            position: fixed;
            right: 64px;
            bottom: 64px;
            transition: all 0.3s ease;
            .union {
                width: 24px;
                height: auto;
            }
            &__text{
                position: absolute;
                height: 100%;
                top: -2px;
                display: flex;
                align-items: center;
                transition: all 0.3s ease;
                transform: translateX(-140px);
                white-space: nowrap;
                color: white;
                user-select: none;
                opacity: 0;
                pointer-events: none;
                &:before {
                    content: '';
                    position: absolute;
                    width: 150%;
                    height: 150%;
                    translate: -25%;
                }

                img:last-of-type {
                    scale: 1.15;
                    margin-top: -1px;
                    margin-left: 5px;
                }

                h3 {
                    color: white;
                    font-size: 12px;
                }
            }
            &:hover{
                & > img {
                    opacity: 0;
                }
                .visioner_logo__text{
                    pointer-events: all;
                    transform: translateX(-158px);
                    opacity: 1;
                }
               
            }
        }
    }
    &-wrapper{ 
        display: grid;
    }
}
.video {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100svh;
    z-index: -1;
    object-fit: cover;
    object-position: right;
    background: $defaultTextColor;
}

@media(max-width: $media-lg) {
    .headline{
       height: 100%;
        &-content{
            max-width: 100%;
            margin: 0;
            display: grid;
            grid-template-columns: none;
            grid-template-rows:1fr 1fr;
            .title-container{
                grid-row: 1;
                align-self:start;
                padding-left: 40px;
                .title{
                    font-weight: 600;
                    font-size: 40px;
                    line-height: 48px;
                    padding-bottom: 8px;
                }
                .description{
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                }
            }
            .visioner_logo{ 
                display: none;
            }
        }
        .btns-container {
            align-self: flex-end;
            padding-left: 40px;
        }
        &-wrapper{ 
            display: grid;
            height: 100%;
        }
    }
}

@media(max-width: $media-md) {
    .headline {
        &-content {
            height: calc(100svh - 64px);
            .title-container {
                padding-left: 32px;
            }
        }
        .btns-container {
            padding-left: 32px;
        }
    }
}

@media(max-width: $media-sm) {
    .headline {
        .title-container{
            grid-row: 1;
            align-self: start;
            padding-left: 24px;
            .title{
                font-weight: 600;
                font-size: 24px;
                line-height: 32px;
                padding-bottom: 8px;
            }
            .description{
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
            }
        }
        .btns-container{
            flex-direction: row;
            grid-column: 1;
            gap: 32px;
            width: 100%;
            background: transparent;
            padding: 32px 22px;
            box-sizing: border-box;

            .container{
                &.first{
                    width: 100%;
                }
                &.second{
                    width: 64px;
                }
            }
        }
    }
}

@media(max-width: $media-xs) {
    .headline {
        .btns-container {
            gap: 8px;
        }
    }
}

