@import 'src/App/style/colors';

.search-result {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 4px 0 12px;
  transition: all 0.15s ease;
  border-radius: 0;
  border-bottom: 1px solid $lightGray;

  .content {
    padding-right: 16px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .name {
      font-family: 'Montserrat';
    }

    .vendor_code {
      padding-top: 6px;
      color: #999999;
      font-family: 'Montserrat';
    }
  }


  .card {
    display: block;
    padding: 0 0 20px 0;
    margin-right: 16px;

    &__header {
      display: flex;
      gap: 32px;

      .img_container {
        width: 120px;
        height: 120px;
        border: 1px solid $gray;
        border-radius: 8px;
        overflow: hidden;

        img {
          height: 100%;
          width: 100%;
        }
      }

      .features {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        flex: 1;
      }

      .price__container {
        justify-content: flex-end;
        text-align: right;
      }
    }

    &:nth-last-child(-n+1) {
      border-bottom: none;
      padding-bottom: 0;
    }

    .options {
      margin-top: 16px;
    }
  }
}