.profile_form{
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  box-sizing: border-box;
  .fields{
    .field_container{
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding-bottom: 16px;
      label{
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #999999;
      }
    }
  }
  .button {
    height: 56px;
  }
}
