.basket{ 
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    cursor: pointer;
    user-select: none;
    .title{ 
        color: #fff;
        font-size: 16px;
    }
    .icon{ 
        font-size: 24px;
        width: 24px;
        height: 24px;
    }
    &_counter{ 
        position: absolute;
        top: -6px;
        right: 6px;
        background-color: #59B968;
        padding: 2px 5px;
        border-radius: 4px;
        .count{ 
            color: #fff;
            font-size: 10px;
            user-select: none;
        }
    }
}