@import 'src/App/style/media';

.registration_form{
  display: flex;
  flex-direction: column;
  height: calc(100% - 50.5px); // - title
  padding-top: 40px;
  .field_container{
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-bottom: 16px;
    label{
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #999999;
    }
  }
  .link_container{
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    gap: 16px;
    padding-top: 16px;
    .link{
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      cursor: pointer;
    }
  }
  .polite_confidentiality{
    display: flex;
    justify-content: center;
    height: 100%;
    .title{
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #999999;
    }
  }
  .footer_container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .button {
    min-height: 56px;
  }
}

@media(max-width: $media-sm) {
  .registration_form{
    padding-top: 32px;
  }
}
