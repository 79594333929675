@import 'src/App/style/colors';

.side-bar,
.order-modal {
  .order-modal-body {
    height: 100%;
    display: flex;
    flex-direction: column;
    .description {
      font-size: 16px;
      line-height: 24px;
      color: $defaultTextColor;
      margin-bottom: 32px;
    }
    .link_container{
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      gap: 8px;
      margin-bottom: 32px;
      .link{
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        cursor: pointer;
        text-decoration: underline;
      }
    }
    .form {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-bottom: 32px;
      .field_container{
        display: flex;
        flex-direction: column;
        gap: 8px;
        .label_container{
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        label{
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: #999999;
        }
      }
    }
    .button.disabled {
      background: $gray;
      opacity: 1 !important;
    }
  }
}

.order-modal {
  .modal-content {
    .title {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 32px;
    }
  }
}

.side-bar {
  .order-modal-body {
    .description {
      margin-top: 32px;
      font-size: 12px;
    }
    .form {
      margin-bottom: 16px;
    }
    .link_container {
      flex-grow: 1;
      justify-content: flex-start;
    }
    .button {
      height: 48px;
    }
  }
}